<template>
  <div v-loading="loading" class="layout">
    <div class="mx-auto flow-root max-w-2xl text-left">
      <ul role="list" class="mb-8">
        <li
          v-for="(notification, indx) in notifications.reverse()"
          :key="notification.Id"
        >
          <div class="relative pb-8">
            <span
              v-if="indx !== notifications.length - 1"
              class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
            <div class="relative flex items-start space-x-3">
              <template v-if="notification.NotificationType === 5">
                <div class="relative">
                  <img
                    class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                    :src="notification.imageUrl"
                    alt=""
                  />

                  <span
                    class="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px"
                  >
                    <ChatAltIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div class="min-w-0 flex-1">
                  <div>
                    <div class="text-sm">
                      <a
                        :href="notification.person.href"
                        class="font-medium text-gray-900"
                        >{{ notification.person.name }}</a
                      >
                    </div>
                    <p class="mt-0.5 text-sm text-gray-500">
                      Commented {{ notification.date }}
                    </p>
                  </div>
                  <div class="mt-2 text-sm text-gray-700">
                    <p>
                      {{ notification.comment }}
                    </p>
                  </div>
                </div>
              </template>
              <template
                v-else-if="notification.NotificationType === 0"
                condition="notification.Type === 0"
              >
                <div>
                  <div class="relative px-1">
                    <div
                      class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white"
                    >
                      <ServerIcon
                        class="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                  <div class="text-sm text-gray-500">
                    {{ notification.Description }}
                    <span class="text-sm text-gray-400">{{
                      formatDate(notification.Created)
                    }}</span>
                  </div>
                </div>
              </template>
              <template
                v-else-if="notification.NotificationType === 4"
                condition="notification.Type === 0"
              >
                <div>
                  <div class="relative px-1">
                    <div
                      class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white"
                    >
                      <ReceiptTaxIcon
                        class="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                  <div class="text-sm text-gray-500">
                    {{ notification.Description }}
                    <span class="text-sm text-gray-400">{{
                      formatDate(notification.Created)
                    }}</span>
                  </div>
                </div>
              </template>
              <template
                v-else-if="notification.NotificationType === 2"
                condition="notification.NotificationType === 2"
              >
                <div>
                  <div class="relative px-1">
                    <div
                      class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white"
                    >
                      <TagIcon
                        class="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="min-w-0 flex-1 py-0">
                  <div class="text-sm leading-8 text-gray-500">
                    <span class="mr-0.5">
                      <a
                        :href="notification.person.href"
                        class="font-medium text-gray-900"
                        >{{ notification.person.name }}</a
                      >
                      {{ " " }}
                      added tags
                    </span>
                    {{ " " }}
                    <span class="mr-0.5">
                      <template
                        v-for="tag in notification.tags"
                        :key="tag.name"
                      >
                        <a
                          :href="tag.href"
                          class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                        >
                          <span
                            class="absolute flex flex-shrink-0 items-center justify-center"
                          >
                            <span
                              :class="[tag.color, 'h-1.5 w-1.5 rounded-full']"
                              aria-hidden="true"
                            />
                          </span>
                          <span class="ml-3.5 font-medium text-gray-900">{{
                            tag.name
                          }}</span>
                        </a>
                        {{ " " }}
                      </template>
                    </span>
                    <span class="whitespace-nowrap">{{
                      notification.date
                    }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import {
  ServerIcon,
  ChatAltIcon,
  TagIcon,
  ReceiptTaxIcon,
  // UserCircleIcon,
} from "@heroicons/vue/solid";
import useNotificationsRepository from "@/composable/useNotificationsRepository";

export default {
  components: {
    ChatAltIcon,
    TagIcon,
    ServerIcon,
    // UserCircleIcon,
    ReceiptTaxIcon,
  },
  setup() {
    const { loading, notifications, getNotifications } =
      useNotificationsRepository();

    onMounted(() => {
      getNotifications();
      console.log({ notifications });
    });

    const formatType = (type) => {
      if (type === 0) return "Organization Access";
      if (type === 1) return "Password Reset";
      if (type === 2) return "Account Lock";
      if (type === 3) return "Role Update";
    };

    const formatDate = (date) => {
      // To calculate the time difference of two dates
      const Difference_In_Time =
        new Date().getTime() - new Date(date).getTime();

      // To calculate the no. of days between two dates
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      return new Intl.RelativeTimeFormat("en", { style: "narrow" }).format(
        -Math.floor(Difference_In_Days),
        "days"
      );
    };

    return {
      loading,
      formatType,
      formatDate,
      notifications,
    };
  },
};
</script>

<style>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow-y: scroll;
  width: 100%;
  position: relative;
}
</style>
