import { ref } from "vue";
import { ElNotification } from "element-plus";
import API from "@/api";

export default function useNotificationsRepository() {
  const notifications = ref([]);
  const loading = ref(false);

  const getNotifications = async () => {
    loading.value = true;
    try {
      let result = await API.Organization.Notifications.get();
      notifications.value = result.data.List;
      loading.value = false;
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Notifications",
        type: "warning",
      });
    }
  };

  return {
    loading,
    notifications,
    getNotifications,
  };
}
